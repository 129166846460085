<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio richiesta</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="richiesta_container">
                    <div class="intestazione">
                        <div class="tipologia">{{ richiesta.richieste_tipologia_value }}</div>
                        <div class="data_creazione">
                            Richiesta effettuata in data <span class="data">{{ dateFormat(richiesta.richieste_creation_date) }}</span>
                        </div>
                    </div>

                    <div class="field">
                        <div class="title">Data inizio</div>
                        <div class="value">
                            {{ richiesta.richieste_dal ? dateFormat(richiesta.richieste_dal) : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Ora inizio</div>
                        <div class="value">
                            {{ richiesta.richieste_ora_inizio ? richiesta.richieste_ora_inizio : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data fine</div>
                        <div class="value">
                            {{ richiesta.richieste_al ? dateFormat(richiesta.richieste_al) : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Ora fine</div>
                        <div class="value">
                            {{ richiesta.richieste_ora_fine ? richiesta.richieste_ora_fine : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Stato</div>
                        <div class="value">
                            <span class="badge" :class="statoRichiesta(richiesta.richieste_stato_id)">
                                {{ richiesta.richieste_stato_value }}
                            </span>
                        </div>
                    </div>
                    <div v-if="richiesta.richieste_protocollo_inps" class="field">
                        <div class="title">Protocollo INPS</div>
                        <div class="value">
                            {{ richiesta.richieste_protocollo_inps ? richiesta.richieste_protocollo_inps : "-" }}
                        </div>
                    </div>
                    <div class="field_note">
                        <div class="title">Note</div>
                        <div class="value">
                            {{ richiesta.richieste_note ? richiesta.richieste_note : "-" }}
                        </div>
                    </div>
                    <div v-if="richiesta.richieste_allegato" class="field_note">
                        <div class="title">Allegato</div>
                        <div class="value">
                            L'allegato per questa richiesta è già stato caricato
                        </div>
                    </div>
                    <div v-else class="field_allegati">
                        <div class="section_title">Allegati</div>
                        <label for="file-upload" class="btn_select_allegato">
                            <ion-icon :icon="attachOutline"></ion-icon>
                        </label>
                        <input id="file-upload" type="file" @change="selezionaAllegato($event)" />

                        <span v-if="currentFile" class="file_name">
                            <div class="file_name_title">
                                {{ currentFile.name }}
                            </div>
                            <div class="remove_allegato">
                                <ion-icon :icon="trash" @click="removeAllegato()" class="icon_remove"></ion-icon>
                            </div>
                        </span>

                        <button v-if="currentFile" class="btn_carica_allegato" @click="caricaAllegato()">Carica allegato</button>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
} from "@ionic/vue";
import { arrowBackOutline, trash, attachOutline } from "ionicons/icons";

import { defineComponent, onMounted, ref, computed } from "vue";

import { openToast } from "@/services/toast";
import { checkField, dateFormat, dateFormatWithMinutes } from "@/services/utils";
import apiRichieste from "@/services/richieste";

export default defineComponent({
    name: "RichiestaDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
    },
    setup(props, context) {
        //console.log(props.data);
        const loading = ref(false);
        const richiesta = ref([]);
        richiesta.value = { ...props.data };

        const currentFile = ref(null);
        function selezionaAllegato(event) {
            //console.log(event);
            currentFile.value = event.target.files[0];
        }

        function removeAllegato() {
            currentFile.value = null;
        }

        async function caricaAllegato() {
            if (!currentFile.value) {
                openToast("Prima devi scegliere un file da caricare", "toast_danger");
                return;
            }
            //console.log(currentFile.value);
            const allegato = currentFile.value;

            try {
                const response = await apiRichieste.saveFile(allegato, richiesta.value.richieste_id);
                if (response.status === 200 && response.data.data.lenght != 0) {
                    const edited = response.data.data[0];
                    //console.log(edited);
                    richiesta.value.richieste_allegato = edited.richieste_allegato;
                    openToast("Allegato caricato con successo", "toast_success");
                } else {
                    openToast("Errore durante il caricamento dell'allegato", "toast_danger");
                }
            } catch (error) {
                console.log(error);
                openToast("Errore durante il caricamento dell'allegato", "toast_danger");
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        const statoRichiesta = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //attesa approvazione
                    className = "badge_warning";
                } else if (statusId == 2) {
                    //approvato
                    className = "badge_success";
                } else if (statusId == 3) {
                    //rifiutato
                    className = "badge_danger";
                }
                return className;
            };
        });

        return {
            loading,
            statoRichiesta,
            checkField,
            dateFormat,
            dateFormatWithMinutes,
            richiesta,
            arrowBackOutline,
            closeModal,
            selezionaAllegato,
            currentFile,
            caricaAllegato,
            removeAllegato,
            trash,
            attachOutline,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}
.description_title {
    color: var(--ion-color-primary, #3880ff) !important;
    margin: 10px 0;
}
/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f2f2f2;
}
.richiesta_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    /*box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);*/
    background: #ffffff;
    box-shadow: 0px 2px 5px 0px #00000040;
}
.richiesta_container .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
    border-bottom: 0.5px solid #7676803d;
}

.richiesta_container .field_allegati {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 16px 0px;
    padding-top: 0px;
}
.richiesta_container .field_allegati .section_title {
    color: #1d1d1b;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
}

.richiesta_container .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 16px 0px;
}
.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #1d1d1b;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
    color: #1d1d1b;
}
.field_note .value {
    color: #979797;
}
.value .badge {
    font-size: 8px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 6px;
    border-radius: 4px;
}

.value .badge_success {
    background-color: #47df0026;
    color: #2fc955;
}
.value .badge_warning {
    /*  background-color: #f97316;
    background-color: rgb(254 243 199);
    color: rgb(245 158 11); */
    background-color: #df860026;
    color: #cd750b;
}
.value .badge_danger {
    /* background-color: rgb(254 226 226);
    color: rgb(239 68 68); */
    background-color: #df000026;
    color: #c9372f;
}
ion-button {
    --color: #ffffff;
}

/**
* Allegato richiesta
*/
.btn_select_allegato {
    display: inline-block;
    width: 100%;
    padding: 8px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
    text-align: center;
    color: #086fa3;
    background: #e36f3026;
    /*box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);*/
}
.btn_select_allegato ion-icon {
    font-size: 16px;
    color: #ffffff;
    background: #e36f30;
    border-radius: 50%;
    padding: 4px;
}
input[type="file"] {
    display: none;
}
.file_name {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}
.file_name_title {
    font-size: 12px;
    font-weight: 500;
}
.btn_carica_allegato {
    margin-top: 24px;
    display: inline-block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    text-align: center;
    background-color: #1c90cf;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.icon_remove {
    /*color: rgb(239 68 68);*/
    background-color: #df000026;
    color: #c9372f;
    font-size: 12px;
    border-radius: 50%;
    padding: 4px;
}
.intestazione {
    font-size: 12px;
    font-weight: 500;
    color: #7e818c;
    margin-bottom: 16px;
}
.intestazione .tipologia {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 12px;
    padding: 9px 12px;
    background-color: #009d27;
    color: #ffffff;
    border-radius: 20px;
    text-align: center;
}
.intestazione .data {
    font-size: 12px;
    font-weight: 700;
}
</style>
