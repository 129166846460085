import axios from "axios";

export default {
    async getImpostazioniHr() {
        const data = new FormData();
        data.append("limit", "300");
        data.append("offset", "0");

        const response = await axios.post(`rest/v1/search/impostazioni_hr`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },
};
